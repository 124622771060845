@use "../../../styles/variables";
@use "../../../styles/mixins";

.main_container {
  .login_wrapper {
    // min-height: calc(100vh - 96px);
    max-width: 450px;
    margin: 16px auto;
    display: flex;
    flex-direction: column;
    h1 {
      text-transform: uppercase;
    }
    form {
      display: flex;
      flex-direction: column;
    }
    .login_link {
      margin: 32px auto;
      text-align: center;
      @include mixins.link($white: true);
    }
    .error_submit {
      @include mixins.error;
    }
  }
}
@media only screen and (min-width: 1440px) {
  .main_container {
    .login_wrapper {
      margin-right: 64px;
      max-width: 450px;
      width: 650px;
      right: 32px;
      padding: 64px 32px;
    }
  }
}
