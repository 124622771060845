@use '../../../../styles/variables';

.tooltip-container {
    // width: 100%;

    a {
        width: 100%;
    }
    
    span {
        z-index: 10;
        position: relative;
    }

    &.is_submit {
        width: 100%;
    }
}

.tooltip-container .tooltip-diff-arrow {
    background-color: variables.$RedWine75;
    z-index: 20;
}

.tooltip-container .tooltip-diff-arrow {
    z-index: 20;
    color: variables.$White;
    background-color: variables.$ShadowGray100;
    
    &__success {
        background-color: variables.$GrapeWineGreen;
        color: variables.$Black;

        .tooltip-arrow {
            background-color: variables.$Black50;
        }
    }

    &__info {
        background-color: variables.$ShadowGray100;
        color: variables.$White;

        .tooltip-arrow {
            background-color: variables.$RedWine;
        }
    }

    &__warning {
        background-color: variables.$Gold;
        color: variables.$Black;

        .tooltip-arrow {
            background-color: variables.$RedWine;
        }
    }

    &__error {
        background-color: variables.$RedError;
        color: variables.$White;
        
        .tooltip-arrow {
            background-color: variables.$Gold;
        }
    }

}


.info-tooltip {
    margin-left: 5px;

    .tooltip-text {
        z-index: 10;
    }

    .icon {
        z-index: 1;
    }
}