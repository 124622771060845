@use "../../../styles/variables";

.lot_form_wrapper {
  // width: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-wrap: wrap;

  .lot_form {
    width: 65%;
  }

  &_row {
    display: flex;
    gap: 16px;
    align-items: center;
    // .submit_field {
    //     margin-left: auto;
    //     input {
    //         background-color: variables.$RedWine50;
    //         &:hover {
    //            background-color: variables.$RedWine75;
    //             color: variables.$White;
    //             box-shadow: variables.$CardShadow;
    //         }
    //         &:active {
    //             background-color: variables.$RedWine;
    //             color: variables.$White;
    //             box-shadow: variables.$CardShadow;
    //         }
    //     }
    // }

    &.vertical {
      flex-direction: column;
      align-items: baseline;
    }
  }


  .lot_label_container {
    margin-top: 54px;
    margin-bottom: 32px;
    flex-grow: 1;
    display: none;
    height: fit-content;
    padding: 15px 15px 15px 15px;
    background-color: variables.$CardLightGray25;
    border-radius: 15px;
    box-shadow: variables.$CardShadow;

    .label_wrapper {
        margin-top: -30px;
        margin-bottom: 20px;

        .list-item {
            margin-left: 10px;
        }

        .label_subtitle {
            margin-bottom: 0;
            margin-top: 10px;
        }

        span {
            font-weight: 500;
        }
    }

    .bio-vegan_wrapper {
        .icon {
            width: 50px;
            background-color: variables.$White;
            border-radius: 50%;
            padding: 3px;
            margin-right: 16px;
        }
    }

    &.open {
        display: block;
    }
}
}
