.info_card_container {
  flex: calc(50% - 16px);

  .info_card {
    min-width: fit-content !important;
    padding: 20px 5px !important;

    .info_card_label {
      font-size: 12px;
      width: auto !important;
      gap: 8px !important;
    }

    .info_card_number {
      margin-left: 5px !important;

      img {
        width: 30px !important;
        z-index: -1;
      }
    }
  }
}