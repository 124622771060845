@use "../../../styles/variables";

.protocol_info_container {
  &_row {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
}
// .primary_container_menu {
//   margin: 20px 0;

//   /* Large or high resolution screen */
//   @media all and (max-width: 1690px) {

//   }

//   /* Desktop or laptop */
//   @media all and (min-width: 1280px) and (max-width: 1689px) {

//   }

//   // /* Tablet in horizontal view */
//   // @media all and (min-width: 737px) and (max-width: 1279px) {
//   // }

//   // /* Tablet in vertical view */
//   // @media all and (min-width: 481px) and (max-width: 736px) {
//   // }

//   /* Smartphone or small tablet */
//   @media all and (max-width: 480px) {
//     display: flex;
//     flex-direction: column;
//     gap: 22px;
//     width: 100%;

//     .ctas {
//       margin-top: 100px;
//       flex-flow: row;
//       width: 92%;
//     }
//   }
// }
