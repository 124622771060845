@use '../../../../styles/variables';


.panel-wrapper {
    display: flex;
    flex-direction: row;
    gap: 32px;

    .left-panel {
        width: 70%;
        flex-grow: 1;
    }

    .right-panel {
        transition: transform 0.3s ease;
        width: 30%;
    }

  // if .right-panel is not present, make .left-panel occupy 100%
  &:not(:has(.right-panel)) {
    .left-panel {
      width: 100%;
    }
  }

}