@use '../../../styles/variables';

.loader_wrapper {
    height: 200px;

    .loader_container {
        position: relative;
    }
}

.loader_container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: variables.$RedWine;
    display: flex;
    justify-content: center;
    align-items: center;
}