@use '../../../../styles/mixins';
@use '../../../../styles/variables';

.button {
    @include mixins.button();
    &_red {
        @include mixins.button($redBg: true);
    }
    &_full {
        @include mixins.button(false, $min-width:250px);
    }
    &_red_full {
        @include mixins.button($redBg: true, $min-width:250px);
    }
}

.active {
    button {
        cursor: default;
    }
    .button {
        background: variables.$GlassLightBlue50;
        box-shadow: variables.$ActiveShadowCoord variables.$ShadowGray;
        .button_hole {
            box-shadow: variables.$ActiveShadowInsetCoord variables.$ShadowGray inset;
        }
        &_red,
        &_red_full {
            background: variables.$GlassLightBlue50;
            box-shadow: variables.$ActiveShadowCoord variables.$RedShadow;
            .button_hole {
                box-shadow: variables.$ActiveShadowInsetCoord variables.$RedShadow inset;
            }
        }
    }
    .button_hole_icon_wrapper {
        transform: none !important;
    }
    .button_label {
        color: variables.$RedShadow;
        
        &.burger_container_action {
            color: variables.$RedError;
        }
    }
    
    .burger_container_action {
        background: transparent;
        box-shadow: none;
    }
}