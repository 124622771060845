// @use '../../../styles/variables';

.warehouse_wrapper {
 
  .cta_wrapper {
    margin-bottom: 16px;

    &.is_treatment {
      margin-bottom: -40px;
    }
  }

  .cards_wrapper {
    margin-bottom: -40px;
  }
}