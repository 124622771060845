@use "../../../styles/variables";

.hub_primary_container {
  
  padding: 32px 32px 32px 32px;
  position: relative;
  top: 50px;
  left: 320px;
  width: calc(100% - 340px);
  // width: 80%;


  /* Large or high resolution screen */
  @media all and (max-width: 1690px) {

  }

  /* Desktop or laptop */
  @media all and (min-width: 1025px) and (max-width: 1689px) {
    padding: 32px 80px 32px 32px;
    position: relative;
    top: 50px;
    left: 310px;
    width: calc(100% - 340px);
  }

  /* Tablet in horizontal view */
  @media all and (min-width: 737px) and (max-width: 1024px) {
    padding: 32px 32px 32px 32px;
    left: 0;
    width: 100%;
  }

  /* Tablet in vertical view */
  @media all and (min-width: 481px) and (max-width: 736px) {
    padding: 32px 32px 32px 32px;
    left: 0;
    width: 100%;
  }

  /* Smartphone or small tablet */
  @media all and (max-width: 480px) {
    padding: 32px 80px 32px 32px;
    position: relative;
    left: 0;
    width: 100%;
  }

  @media all and (max-width: 440px) {  
    padding: 32px 32px 32px 32px;
  }

  @media all and (max-width: 380px) {  
    padding: 32px 32px 32px 32px;
  }

  &_wrapper {
    // /* Tablet in vertical view */
    @media all and (min-width: 481px) and (max-width: 736px) {
      // display: flex;
      // flex-direction: column;
      // gap: 32px;
      // margin-top: -20px;
    }
    @media all and (max-width: 480px) {      
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }
  
  &_row {
    display: flex;
    gap: 16px;

    &.cards {
      justify-content: flex-end;
      min-height: 100px;
      margin-top: -20px;

      @media all and (min-width: 1025px) and (max-width: 1054px) {
        justify-content: flex-start;
      }
      /* Tablet in vertical view */
      @media all and (min-width: 481px) and (max-width: 736px) {
        justify-content: flex-start;
        flex-wrap: wrap;
        min-height: 80px;
      }
    }
    
    &.end {
      justify-content: end;
      margin-bottom: -80px;
      margin-bottom: -20px;
      margin-top: -20px;
    }

    .operations_list_table {
      width: 100%;
      flex-basis: 100%;
      margin-top: -50px;

      &.margin_top {
        margin-top: 40px;
      }
    }

    &.no_wrap {
      margin-bottom: 16px;
    }

    @media all and (max-width: 480px) {      
      display: flex;
      gap: 32px; 
    
      &.no_wrap {
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
      }
    }

    
    .operation_dropdown {
      
      @media all and (max-width: 480px) {
        display: flex;
        flex-basis: 100%;
      }
    }

    .operation_dropdown {
      @media all and (max-width: 480px) {
        display: flex;
        flex-basis: 100%;
      }
    }

    .operation_additional {
      display: flex;
      gap: 16px;
      
      @media all and (max-width: 480px) {       
        display: flex;
        gap: 32px;
        margin-right: 0px;
      }
      @media all and (max-width: 450px) {       
        gap: 16px;
        margin-right: 0px;
      }
    }
  }
  
  &_menu {
    display: flex;
    gap: 32px;
    flex-direction: column;
  
    @media (max-width: 480px) {
      margin-top: -20px;
    }
  }
}

.hub_primary_container_row_warning_wrapper {
  // margin-left: auto;
  display: flex;
  // gap: 16px;
  // height: 70px;
  
  /* Tablet in vertical view */
  @media all and (min-width: 481px) and (max-width: 736px) {
    flex-wrap: wrap;
    row-gap: 16px;
  }

  &_wrapper {
    @media all and (max-width: 480px) {      
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }

  &_upper {

    flex-wrap: nowrap;
    display: flex;
    gap: 16px;

    .modify-button {
      display: flex;
      flex-direction: column;
      align-self: center;

      .cta {
        padding: 5px 8px;
        // font-size: 10px;
      }  
    }
    
    @media all and (min-width: 481px) and (max-width: 736px) {
      width: 100%;
    }
    
    @media all and (max-width: 480px) {
      // display: flex;
      // flex-wrap: nowrap;
      // gap: 16px;
    }

    @media all and (max-width: 450px) {  
      flex-wrap: wrap;
    }
    @media all and (max-width: 380px) {  
      display: flex;
      flex-wrap: wrap;
      // gap: 16px;
    }
  }  
  
  @media all and (max-width: 480px) {
    display: flex;
    flex-flow: wrap;
  //   min-width: 10%;
  //   flex: 1 0 182px;
    gap: 16px;
  }
}

.warning_wrapper {
  border-radius: variables.$ButtonRadius;
  box-shadow: variables.$CardShadow;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 16px;
  font-weight: 600;
  width: 200px;

  @media all and (min-width: 481px) and (max-width: 600px) {  
    width: 180px;
  }

  @media all and (max-width: 480px) {  
    width: unset;
  }

  &.warning_tbd {
    @media all and (min-width: 481px) and (max-width: 736px) {
      flex-basis: 80%;
    }
    @media all and (max-width: 450px) {  
      flex-basis: 80%;
    }
    background-color: variables.$Gold30;
    @media all and (max-width: 380px) {  
      flex-grow: 1;
    }

  }
  &.warning_exp {
    background-color: variables.$Gold;
    // margin-right: 16px;
    
    @media all and (min-width: 481px) and (max-width: 736px) {
      flex-basis: 100%;
    }
    @media all and (max-width: 450px) {  
      flex-basis: 100%;
    }
    
    /* Tablet in vertical view */
    @media all and (min-width: 481px) and (max-width: 736px) {
      margin-right: 0px;
    }
    @media all and (max-width: 480px) {
      margin-right: 0px;
    }

    @media all and (max-width: 380px) {  
      flex-grow: 1;
    }
  }
  &.warning_due {
    background-color: variables.$RedError55;

    @media all and (min-width: 737px) {
      margin-left: 16px;
    }
    
    /* Tablet in vertical view */
    @media all and (min-width: 481px) and (max-width: 736px) {
      flex-basis: 100%;
    }
    @media all and (max-width: 480px) {      
      flex-basis: 100%;
    }
  }
  &.warning_ok {
    background-color: variables.$GrapeWineGreen;
    // color: variables.$Black;
    flex-grow: 1;
  }

  &.is_grey {
    background-color: variables.$CardLightGray25;
  }

  .warning_label {
    width: 75%;
  }
  .warning_value {
    font-size: 24px;
  }
}
.interval_row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.interval_value {
  display: flex;
  gap: 4px;
  font-size: 13px;
  font-weight: 600;

  span {
    margin-right: 4px;
  }

  @media all and (max-width: 350px) {
    // flex-basis: 100%;
    flex-wrap: wrap;
  
    .react-datepicker-wrapper {
      display: flex;
      flex: 1 1 100%;
    }
}
}

.react-datepicker__input-container {
  input {
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
  }
}

.alert_no_info {
  &.margin_top {
    margin-top: -40px;
  }
}
