@use "../../../styles/variables";

.top_burger_bar {
  height: 40px;
  width: 100%;
  background: variables.$RedWine;
  z-index: 17;
  position: fixed;

  .bars {
    color: variables.$White;
    width: 50px;
    padding: 4px 8px;
    margin-bottom: -1px;
  }
}

.blocker {
  position: absolute;
  width: 100%;
  height: calc(100% - 40px);
  margin-top: 40px;
  background-color: variables.$Black50;
  z-index: 2;
}

.burger_container {
  // background-color: variables.$RedWine;
  width: calc(100% - 40px);
  width: 75%;
  max-width: 300px;
  background: variables.$RedWine;
  border-bottom-right-radius: variables.$CardRadius;

  // backdrop-filter: blur(30px) saturate(2);
  // -webkit-backdrop-filter: blur(30px) saturate(2);
  z-index: 16;
  // display: flex;
  // flex-direction: column;
  // flex-shrink: 0;

  height: 100vh;
  border-bottom-right-radius: 20px;
  position: relative;
  z-index: 16;
  // display: flex;
  // flex-direction: column;

  flex: 0 0 200px;
  flex-direction: column;
  backdrop-filter: blur(30px) saturate(2);
  -webkit-backdrop-filter: blur(30px) saturate(2);
  padding-inline: 0;
  padding-block: 13px;
  //   overflow-y: auto;
  //   overflow-x: hidden;

//   transition: 0.5s ease translate;
//   translate: -100% 0;
//   will-change: transform;
  height: 90vh;
  white-space: nowrap;
  margin-top: 39px;
  position: fixed;

  transition : all 2s ease;
  transition: 0.5s ease translate;
  // place it initially at -100%
  transform : translate(-100%);
    // display: none;


    opacity: 0;
    transition: all 1s ease-in;
  &.open {
    // display: flex;
    opacity: 1;

    // translate: 0 0;
    transform : translate(0);
  }
  
  &::after {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    background: transparent;
    top: 0px;
    right: -40px;
    border-radius: 40px;
    box-shadow: -20px -20px 0 0 #64242e;
    z-index: 3;
  }

  &_action {
    font-size: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    // background: variables.$RedWine;
    background: none;
    padding: 0;
    height: 47px;
    backdrop-filter: unset;
    box-shadow: none;
  }
  a:first-child {
    margin-top: 30px;
  }

  &_navigation {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 12px;
    padding-bottom: 22px;
  }
  &_primary,
  &_secondary {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
  }

  &_primary {
    margin-bottom: auto;
  }

  &_secondary {
    margin-bottom: 20px;
    width: 100%;

    .dropdown-secondary {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}
// }
