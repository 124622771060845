@use '../../../styles/variables';

.footer_container {
  background: variables.$RedWine10;
  margin-top: 50px;
  position: absolute;
  width: 100%;
  padding: 20px;
  padding-left: 320px;
  
  .top_content {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: space-around;
    
    .column {
        width: 200px;

      h5 {
        margin-bottom: 0px;
      }

      .content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
      }

      .link {
        margin-bottom: 3px;
        line-height: 1.375rem;
        padding-left: 10px;
        padding-right: 10px;

        &:hover {
          cursor: pointer;
          font-weight: 500;
          color: variables.$White;
          background-color: variables.$RedWine;
          border-radius: 4px;
        }
      }
    }
  }

  .bottom_content {

  }
}
