@use '../../../styles/variables';

.protocols {
    width: 100%;
    height: 100%;
    &_alert {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.protocols_list {
    margin-top: 32px;
}

.protocols_grid {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    margin-top: 32px;
    // justify-content: space-between;
}


.protocol_tile {
    width: 250px;
    height: 200px;
    // background: variables.$GlassLightBlue50;
    position: relative;
    z-index: 1;
    backdrop-filter: variables.$Blur;
    box-shadow: variables.$CardShadow;
    overflow: hidden;
    cursor: pointer;
    transition: box-shadow variables.$TransitionTime variables.$TransitionMode;
    border-radius: variables.$CardRadius;
    // border: 2px solid variables.$LightBlue;
    padding: variables.$Padding;
    // background-color: variables.$GlassLightBlue25;
    // background-color: variables.$CardLightGray25;
    background-color: variables.$GlassLightBlue25;

    &_progress {
        position: absolute;
        bottom: 0;
        background: variables.$RedWine;
        width: 100%;
        left: 0;
        border-bottom-left-radius: variables.$ButtonRadius;
        border-bottom-right-radius: variables.$ButtonRadius;
        z-index: 0;
    }
    &_content {
        position: relative;
        z-index: 1;
        // background: variables.$White;
        border-radius: variables.$ButtonRadius;
        padding: 8px;
        // box-shadow: variables.$ShadowInsetCoord variables.$ShadowGray inset;
        transition: box-shadow variables.$TransitionTime variables.$TransitionMode;

        &_name {
            font-weight: 600;
            margin-bottom: 4px;
            padding-bottom: 4px;
            font-size: 18px;
            border-bottom: 1px solid variables.$Black50;
            width: 100%;
                
            &_wrapper {
                display: inline-block;
                display: flex;
                
                &_icon {
                   margin-left: 14px;
                }
            }
        }

        &_subs {
            margin-bottom: 10px;

            p {
                text-align: right;
                // margin-bottom: 5px;
            }
        }
    }
    &:hover {
        box-shadow: variables.$HoverShadowCoord variables.$ShadowGray;
        .lot_tile_content {
        //   box-shadow: variables.$HoverShadowInsetCoord variables.$ShadowGray inset;
        }
    }

}