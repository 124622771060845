@use '../../../styles/variables';

.analysis_plot {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-left: 16px;

    .select_field_controlled_container {
        flex-basis: 300px;
    }
}

.custom-tooltip {
    // background: variables.$GlassLightBlue;
    background: variables.$White;
    border: 1px solid variables.$Black25;
    border-radius: variables.$ButtonRadius;
    padding: 8px;
    // box-shadow: variables.$ShadowInsetCoord variables.$ShadowGray inset;
    transition: box-shadow variables.$TransitionTime variables.$TransitionMode;
    
    &_label {
        font-weight: 600;
        margin-bottom: 4px;
        padding-bottom: 4px;
        border-bottom: 1px solid variables.$Black50;
    }

    &_date {
        font-weight: 400;
        margin-bottom: 4px;
        padding-bottom: 4px;
    }
    
    &_bold {
        font-weight: 500;
    }

    &:hover {
        box-shadow: variables.$HoverShadowCoord variables.$ShadowGray;
        .analysis_tile_content {
          box-shadow: variables.$HoverShadowInsetCoord variables.$ShadowGray inset;
        }
    }
}
