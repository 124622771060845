@use "../../../../styles/variables";

.bottle_recap {
  min-width: 180px;
  max-width: 280px;
  padding: 2px 16px;
  margin: 0px 12px 16px 12px;
  display: flex;
  flex-direction: column;
  width: 280px;

  p {
    text-align: center;

    &.drop {
      margin-top: 6px;
    }
  }

  &.recap {
    display: block;
    min-width: unset;
    max-width: none;
    width: auto;
  }
  
  .normal {
    font-weight: 400;
  }

  .bold {
    font-weight: 500;
  }

  .bottle_data {
    display: flex;
    flex-direction: column;
    align-items: center;

    .cap_icon {
      display: flex;
      align-items: center; // Keeps the cap icon and text on the same row
      justify-content: center;
      text-align: center;
      gap: 5px; // Spacing between the icon and text
      width: 100%;
      margin-bottom: 8px;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .bottle_icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 5px;

      .icon {
        display: flex;
        justify-content: center;
        transform: rotate(-45deg);
        margin-bottom: 8px;
      }
    }
  }
}
