@use '../../../../styles/variables';


.tank_info_container {
    margin-bottom: 32px;
    &_row {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }
}