@use "../../../styles/variables";

.header {
  width: calc(100% - 8px);
  height: 60px;
  color: variables.$White;
  background: variables.$RedWine;
  display: flex;
  align-items: center;
  border-bottom-right-radius: variables.$CardRadius;
  box-shadow: variables.$CardShadow;

  // fix navbar
  position: fixed;
  z-index: 15;

  &_wrapper {
    padding: 0 32px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .clock {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      &_analog {
        position: relative;
        &_circle {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          border: 2px solid variables.$White;
        }
        &_hour,
        &_minute,
        &_second {
          transform-origin: bottom;
          position: absolute;
          background-color: variables.$White;
          width: 2px;
          height: 12px;
          left: calc(50% - 1px);
          top: 6px;
          transform: translateX(-50%);
          border-radius: 2px;
        }
        &_hour {
          height: 8px;
          width: 2px;
          left: calc(50% - 1px);
          top: 10px;
        }
        &_second {
          width: 1px;
          background-color: variables.$White;
          left: calc(50% - 0.5px);
        }
        &_pin {
          width: 4px;
          height: 4px;
          background-color: variables.$White;
          position: absolute;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 5;
        }
      }
      &_digital {
        font-weight: 700;
        font-size: 14px;
      }
    }
    &_user {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
      &_badge {
        background-color: variables.$GlassLightBlue50;
        text-transform: uppercase;
        border-radius: variables.$CardRadius;
        backdrop-filter: blur(20px);
        box-shadow: variables.$CardShadow;
        font-size: 14px;
        padding: 4px 8px;

        &:hover {
          cursor: default;
        }
      }

      &_label {
        &:hover {
          cursor: default;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    &_bell {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;

      &:hover {
        cursor: pointer;
      }

      .socket {
        top: -10px;
        left: -34px;
        position: relative;  
        color: variables.$RedError;
        fill: variables.$RedError;
        
        &.connected {
          color: variables.$AddRowGreen;
          fill: variables.$AddRowGreen;
        }

        &.fix {
          left: -62px;
        }
      }
    }
  }
}
