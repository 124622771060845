@use "../../../styles/variables";

.card_wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;

  .card_title {
    color: variables.$RedWine;
    margin-bottom: -28px;
    margin-top: 20px;
  }

  .card {
    border: 1px solid variables.$RedWine;
    background-color: #eae1d030;
    border-radius: 12px;
    padding: 8px;
  }

  .report_card_wrapper {
    .report_card {
      display: flex;
      flex-direction: row;
      gap: 16px;

      .report_card_img {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          margin-bottom: 6px;
        }

        .img {
        }
      }

      .report_card_content {
        padding-top: 26px;
      }
    }
  }
}
