@use "../../../../styles/variables";

.trigger-wrapper {
  // position: relative;
  // margin-top: 150px;
  // display: inline-block;
  // color: #fff;
  &:hover {
    cursor: pointer;
  }

}

.trigger-span {
  position: relative;
  top: -10px;
  right: 17px;
  background: variables.$WhiteWine;
  color: variables.$Black;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.motion-aside-container {
  width: min(38%, 45%);
  position: absolute;
  z-index: 2;
  left: 57.2%;
  top: 60px;
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  box-shadow: variables.$CardShadow;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  cursor: default;
  z-index: 10;
  
  h3 {
    margin: 0;
    text-align: left;
    color: variables.$White;
  }

  .switch-field .active {
    color: variables.$White;
  }
  
  .motion-section-content {
    background: variables.$White;
    // height: 70%;
    max-height: 400px;
    min-height: 260px;
    overflow-y: scroll;
    overflow-x: hidden;
    color: variables.$RedWine;
    padding: 0.2rem;
    position: relative;
    top: 55px;
    
    h4 {
      margin: 0;
      text-align: center;
      padding: 2rem;
      color: variables.$Black50;
      font-size: 20px;
    }
  }

  .icon-wrapper {
    width: 32px;
  }

  .motion-article-item {
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    gap: 8px;
    padding: 0.8rem;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    align-items: center;

    .tools {
      display: flex;
      gap: 10px;
      align-items: flex-end;

      .remove {
        &:hover {
          cursor: pointer;
        }
      }
      
      .pulsating-dot {
        @keyframes pulsate {
          0% {
            transform: scale(0.1, 0.1);
            opacity: 0.0;
          }
          50% {
            opacity: 1.0;
          }
          100% {
            transform: scale(1.2, 1.2);
            opacity: 0.0;
          }
        }

        &:hover {
          cursor: pointer;
        }
        
        .circle {
          position: relative;
          width: 15px;
          height: 15px;
          background-color: #3498db;
          border-radius: 50%;
          position: relative;
          
          &.success {
            background-color: variables.$AddRowGreen;
          }
        
          .ring {
            border: 3px solid #3498db;
            border-radius: 30px;
            height: 30px;
            width: 30px;
            position: absolute;
            top: calc(-50%);
            left: calc(-50%);
            animation: pulsate 1s ease-out;
            animation-iteration-count: 3;
            opacity: 0;

            &.success {
              border: 3px solid variables.$GrapeWineGreen;
            }
          }
        }
      }
    }
  }

  .header {
    background: variables.$RedWine;
    color: variables.$White;
    margin: 0;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 38%;
  }

  .unread-filter {
    display: flex;
    align-items: center;
    gap: 8px;
    label {
      cursor: pointer;
    }
  }

  .footer {
    height: 120px;
    padding-top: 10%;
    background: variables.$RedWine;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    .footer_wrapper {
      display: flex;
      flex-grow: 1;
      gap: 12px;
      margin-top: 55px;
    }
  
    button {
      max-width: 49%;
    }
  }
}

