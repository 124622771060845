@use '../../../styles/variables';

.user_form_wrapper {
    width: fit-content;
    &_row {
        display: flex;
        gap: 16px;
        align-items: flex-start;

        &.center {
            align-items: center;
        }

        .lang_icon {
            width: 40px;
            margin-top: 45px;
        }

        .submit_field {
            margin-left: auto;
            input {
                background-color: variables.$RedWine50;
                &:hover {
                   background-color: variables.$RedWine75;
                    color: variables.$White;
                    box-shadow: variables.$CardShadow;
                }
                &:active {
                    background-color: variables.$RedWine;
                    color: variables.$White;
                    box-shadow: variables.$CardShadow;
                }
            }
        }
    }
}