@use '../../../styles/variables';

.wineries {
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;

    &_alert {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.wineries_list {
    margin-top: 32px;
    width: 100%;
}

.wineries_grid {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    
    &.is_modal {
        justify-content: center;
        align-items: center;
    }
}

.winery_tile {
    width: 180px;
    height: 180px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 4px;
    box-shadow: variables.$ShadowInsetCoord variables.$ShadowGray;
    padding: 16px;
    background-color: variables.$CardLightGray15;
    background-color: variables.$White;
    box-sizing: border-box;
    border: 2px solid transparent;
    align-items: center;
    justify-content: center;
    
    &.is_selector {
        background-color: variables.$CardLightGray15;
    }

    &_content {
        position: relative;
        z-index: 1;
        border-radius: variables.$ButtonRadius;
        padding: 8px;
        transition: box-shadow variables.$TransitionTime variables.$TransitionMode;
        text-align: center;

        &_name {
            font-weight: 600;
            margin-bottom: 4px;
            padding-bottom: 4px;
        }

        &_img_container {
            img {
                width: 80px;
                color: red;
            }
        }
    }

    &:hover {
        box-shadow: variables.$HoverShadowCoord variables.$ShadowGray;
        cursor: pointer;
    }

}