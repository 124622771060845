@use '../../../styles/variables';


.substances {
    width: 100%;
    height: 100%;
    &_alert {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.substances_list {
    margin-top: 32px;
    width: 100%;
}

.substances_grid {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    // justify-content: space-between;
}

.substance_tile {
    width: 150px;
    height: 200px;
    background: variables.$GlassLightBlue;
    border-radius: variables.$ButtonRadius;
    padding: 8px;
    position: relative;
    z-index: 1;
    backdrop-filter: variables.$Blur;
    box-shadow: variables.$CardShadow;
    overflow: hidden;
    cursor: pointer;
    transition: box-shadow variables.$TransitionTime variables.$TransitionMode;
    &_progress {
        position: absolute;
        bottom: 0;
        background: variables.$RedWine;
        width: 100%;
        left: 0;
        border-bottom-left-radius: variables.$ButtonRadius;
        border-bottom-right-radius: variables.$ButtonRadius;
        z-index: 0;
    }
    &_content {
        position: relative;
        z-index: 1;
        background: variables.$White;
        border-radius: variables.$ButtonRadius;
        padding: 8px;
        box-shadow: variables.$ShadowInsetCoord variables.$ShadowGray inset;
        transition: box-shadow variables.$TransitionTime variables.$TransitionMode;

        &_name {
            font-weight: 600;
            margin-bottom: 4px;
            padding-bottom: 4px;
            border-bottom: 1px solid variables.$Black50;
        }
    }
    &:hover {
        box-shadow: variables.$HoverShadowCoord variables.$ShadowGray;
        .substance_tile_content {
          box-shadow: variables.$HoverShadowInsetCoord variables.$ShadowGray inset;
        }
    }

}