@use '../../../../styles/mixins';
@use '../../../../styles/variables';

.button {
    @include mixins.button();
    &_red {
        @include mixins.button($redBg: true);
    }
    &_full {
        @include mixins.button(false, $min-width:180px);
    }
    &_red_full {
        @include mixins.button($redBg: true, $min-width:180px);
    }
}
