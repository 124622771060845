@use "../../../styles/variables";

.sensor {
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  box-shadow: variables.$ShadowInsetCoord variables.$ShadowGray;
  padding: 16px;
  background-color: variables.$CardLightGray15;
  height: 180px;
  width: 350px;
  box-sizing: border-box;
  border: 2px solid transparent;
  transition: background-color 0.75s ease;

  &.success {
    background-color: variables.$GrapeWineGreen50;
  }

  &.error {
    background-color: variables.$RedError65;
  }

  &.selected {
    border: 2px solid variables.$RedWine;
  }

  &:hover {
    cursor: pointer;
    box-shadow: variables.$HoverShadowCoord variables.$ShadowGray;

    // .analysis_tile_content {
    //     box-shadow: variables.$HoverShadowInsetCoord variables.$ShadowGray inset;
    // }
  }

  &.no_hover {
    cursor: default;
  }

  .title_wrapper {
    display: flex;
    align-items: baseline;
    justify-content: center;
    width: 100%;
    height: 20px;
    
    .sensor_title {
      text-align: center;
      margin-bottom: 0px;
    }

    .dot {
      width: 10px;
      height: 10px;
      margin-left: 10px;
      border-radius: 50%;
      display: inline-block;
      border: 1px solid transparent;
      
      &.enabled {
        background-color: variables.$AddRowGreen;
        border: 1px solid variables.$White;
      }

      &.disabled {
        background-color: variables.$RedError;
        border: 1px solid variables.$White;
      }
    }
  }

  .min_max {
    font-weight: 500;
  }

  .sensor_data {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    row-gap: 32px;

    .column {
      flex: 1;

      img {
        width: 50px;
      }

      &.column2 {
        flex: 2;
      }

      .current,
      .set_point {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 5px;
        align-items: center;

        .actual_value {
          font-size: 24px;
          font-weight: 600;
          text-align: center;
        }
        .actual_time {
          font-size: 14px;
          font-weight: 400;
          text-align: center;
        }

        .set_point_title,
        .set_point_value {
          font-size: 14px;
          font-weight: 400;
          text-align: center;

          .unit {
            font-size: 14px;
            font-weight: 300;
          }
        }

        .set_point_title {
          font-weight: 500;
        }

        .unit {
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
  }

  .sensor_footer {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-bottom: -20px;

    .column {
      flex: 1;

      &.column1 {
        font-size: 13px;

        .switch_field {
          gap: 4px;
        }
      }

      &.column2 {
        flex: 2;

        .select_field_controlled_container {
          max-width: 100px;
          margin: 0;
          min-width: unset;

          .select_field_wrapper_container {
            min-height: unset;
            font-size: 13px;
            padding: 4px 8px;
          }
        }

        .enable-button {
          background-color: unset;
          color: variables.$Black50;
          font-size: 13px;
          box-shadow: none;
          display: block;
          margin-left: auto;
          width: max-content;
          padding: 0;

          &:hover {
            text-decoration: underline;
            box-shadow: none;
          }
        }
      }

      &.column3 {
        flex: 1;

        .modify_set_point {
          button {
            background-color: unset;
            color: variables.$Black50;
            font-size: 13px;
            box-shadow: none;
            display: block;
            margin-left: auto;
            width: max-content;
            padding: 0;

            &:hover {
              text-decoration: underline;
              box-shadow: none;
            }
          }
        }
      }
    }
  }

  .sensor_footer > .column3 {
    flex: 1;
  }

  .sensor_footer > .column2:not(:last-child) {
    flex: 2;
  }
}
