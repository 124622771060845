@use "../../../styles/variables";

.table-skeleton {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 40px;
  
  table {
    margin-top: 10px;

    h1 {
      font-size: 18px;
    }
  }

  &.no-header {

    table {
      box-shadow: none;
    }

    table tbody tr:nth-of-type(even) {
      background: none;
    }

    tr {
      border-bottom: 1px solid black;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .switch-skeleton {
    width: 210px;
    display: flex;
    gap: 30px;
  }

  .search-skeleton {
    width: 100%;
    height: 100%;
    line-height: 1;
  }

  .filter-skeleton-div {
    width: 100%;
    height: 50px;
    line-height: 1;
    margin-top: -70px;
    display: flex;
  }

  .mobile-filter-skeleton-div {
    width: 40%;
    height: 50px;
    line-height: 1;
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-left: auto;

    span {
      width: calc(100% / 4);
      height: 100%;
    }

    .mobile-filter-skeleton {
      width: 100%;
    }
  }
}

.analysis-grid-skeleton {
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 280px;

  span {
    width: 100%;
  }

  .analysis-grid-item-skeleton {
    border-radius: variables.$CardRadius;
    width: 100%;
    line-height: 1;
    height: 100%;
  }
}

.info-cards-skeleton {
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 80px;

  .info-card-skeleton {
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 16px;
    font-weight: 600;
    min-width: 200px;
    width: 100%;
    line-height: 1;
    height: 100%;
  }
}

.cards-skeleton {
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 80px;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 90px;

  span {
    height: 100%;
    width: calc(100% / 3 - 16px);
  }

  .card-skeleton {
    border-radius: 4px;
    width: 100%;
    line-height: 1;
    height: 100%;
  }
}
