@use '../../../../styles/variables';


.recursive_date {
 
    margin-top: 10px;
    height: 250px;
    overflow: auto;

    li {
        display: flex;
    }

    p {
        font-weight: 450;
    }

    input {
        margin-right: 10px;
        width: 24px;
        height: 24px;
        // margin-top: -20px;
        // margin-bottom: -20px;
        accent-color: variables.$RedWine;
  
        &.winemaking {
          width: 24px;
          height: 24px;
          margin-left: 15px;
          margin-top: 0px;
        }
    }
}

.operation_recap {
    
    .batch_name {
 
        &.reverse {
            transform: rotate(180deg);
            margin: 0px;
        }
    }

    .graph {
        // transform: scale(0.9);

        h6 {
            margin-bottom: 10px;
        }
        
        &.reverse {
            transform: scale(0.9) rotate(180deg);
            margin: 0px;

            &.no-scale {
                transform: scale(1) rotate(180deg);
            }
        }
    }

    .analysis_wrapper {
        width: fit-content;
        &_row {
            display: flex;
            gap: 16px;
            align-items: center;
            margin-top: 16px;
            
            .submit_field {
                margin-left: auto;
                input {
                    background-color: variables.$RedWine50;
                    &:hover {
                    background-color: variables.$RedWine75;
                        color: variables.$White;
                        box-shadow: variables.$CardShadow;
                    }
                    &:active {
                        background-color: variables.$RedWine;
                        color: variables.$White;
                        box-shadow: variables.$CardShadow;
                    }
                }
            }
        }
    }
}
