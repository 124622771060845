@use 'variables';
@mixin grid() {
    padding: 16px;
    @media only screen and (min-width: 1440px) {
        padding: 0;
        max-width: 1110px;
        margin: auto;
    }
}

@mixin card($glass: true) {
    border-radius: variables.$CardRadius;
    box-shadow: variables.$CardShadow;
    padding: variables.$Padding;
    margin: variables.$Margin;
    @if $glass {
        background-color: variables.$GlassLightBlue;
        backdrop-filter: variables.$Blur;
    } @else {
        background-color: variables.$RedWine;
        color: variables.$White;
    }
}

@mixin button($redBg: false, $min-width: 48px) {
    cursor: pointer;
    border: none;
    background: variables.$GlassLightBlue20;
    padding: 8px;
    backdrop-filter: variables.$Blur;
    min-width: $min-width;
    box-shadow: variables.$ShadowCoord variables.$ShadowGray;
    border-radius: variables.$ButtonRadius;
    text-align: left;
    position: relative;
    height: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    transition: box-shadow .1s ease-in-out, background .3s ease-in-out;
    &_label {
        color: variables.$Black;
        font-size: 15px;
        font-weight: 600;
        cursor: inherit;
    }
    &_hole {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 8px;
        bottom: 8px;
        box-shadow: variables.$ShadowInsetCoord variables.$ShadowGray inset;
        border-radius: variables.$ButtonRadius;
        overflow: hidden;
        transition: box-shadow .1s ease-in-out;
        &_icon_wrapper {
            display: flex;
            gap: 4px;
            transition: transform .2s ease-in-out;

        }
        &.right {
            .button_hole_icon_main {
                transform: rotate(180deg) translate(7px, -1px);
            }
            .button_hole_icon_wrapper {
                transform: translate(-25px);
            }
        }
            
        &.left {
            .button_hole_icon_main {
               transform: translate(7px);
           }
            .button_hole_icon_wrapper {
                transform: translate(25px);
            }
        } 
    }
    &:hover {
        box-shadow: variables.$HoverShadowCoord variables.$ShadowGray;
        .button_hole {
            box-shadow: variables.$HoverShadowInsetCoord variables.$ShadowGray inset;
        }
    }
    &:active {
        box-shadow: variables.$ActiveShadowCoord variables.$ShadowGray;
        .button_hole {
            box-shadow: variables.$ActiveShadowInsetCoord variables.$ShadowGray inset;
        }
    }
    &:disabled {
        cursor: default;
        &_label {
            opacity: .25;
        }
        box-shadow: variables.$DisabledShadowCoord variables.$ShadowGray;
        .button_hole {
            box-shadow: variables.$DisabledShadowInsetCoord variables.$ShadowGray inset;
            .button_hole_icon_main {
                opacity: .25;
            }
            &.right .button_hole_icon_main {
                transform: rotate(180deg) translate(16px, -1px);
            }
            &.left .button_hole_icon_main {
                transform: translate(16px);
            }
        }
    }
    @if $redBg {
        box-shadow: variables.$ShadowCoord variables.$RedShadow;
        .button_label {
            color: variables.$White;
        }
        .button_hole {
            box-shadow: variables.$ShadowInsetCoord variables.$RedShadow inset;
            background: variables.$RedWine;
        }
        &:hover {
            box-shadow: variables.$HoverShadowCoord variables.$RedShadow;
            .button_hole {
                box-shadow: variables.$HoverShadowInsetCoord variables.$RedShadow inset;
            }
        }
        &:active {
            box-shadow: variables.$ActiveShadowCoord variables.$RedShadow;
            .button_hole {
                box-shadow: variables.$ActiveShadowInsetCoord variables.$RedShadow inset;
            }
        }
        &:disabled {
            box-shadow: variables.$DisabledShadowCoord variables.$RedShadow;
            .button_hole {
                box-shadow: variables.$DisabledShadowInsetCoord variables.$RedShadow inset;
            }
    }
    } @else {
        .button_hole {
            background: variables.$White;
        }
    }
}

@mixin error() {
    color: variables.$RedError;
    border-radius: 8px;
    padding: 16px;
    margin: 8px;
    border: 2px solid variables.$RedError;
    background: variables.$White;
}

@mixin link($white: false) {
    font-size: 16px;
    font-weight: 500;
    position: relative;
    text-decoration: none;
    z-index: 15;
    &::before {
        z-index: -1;
        content: "";
        bottom: -3px;
        left: -4px;
        width: 100%;
        height: 8px;
        position: absolute;
    }
    &::after {
        z-index: -5;
        content: "";
        bottom: -3px;
        left: calc(50% - 4px);
        width: 0;
        height: 8px;
        position: absolute;
        transition: all variables.$TransitionTime variables.$TransitionMode;
    }
    &:hover {
        &::after {
            width: 100%;
            left: -4px;
        }
    }

    @if $white {
        color: variables.$White;
        &::before {
            background-color: variables.$GlassLightBlue25;
        }
        &::after {
            background-color: variables.$GlassLightBlue50;
        }
        &:active {
            &::after {
            background-color: variables.$GlassLightBlue;
            }
        }
    } @else {
        color: variables.$Black;
        &::before {
            background-color: variables.$WhiteWine50;
        }
        &::after {
            background-color: variables.$WhiteWine75;
        }
        &:active {
            &::after {
            background-color: variables.$WhiteWine;
            }
        }
    }
}