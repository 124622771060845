@use "mixins";

* {
  box-sizing: border-box;
  font-family: $FontStack !important;
  outline: none !important;
}

body {
  background-color: $White;
  color: $Black;
}

h1 {
  font-size: 36px;
  font-weight: 700;
  margin: 0 0 $Margin 0;
  line-height: 140%;
}

h2 {
  font-size: 32px;
  font-weight: 700;
  margin: 0 0 16px 0;
  line-height: 120%;
}

h3 {
  font-size: 32px;
  font-weight: 600;
  margin: 0 0 $Margin 0;
  line-height: 120%;
  color: $Black75;
}

h4 {
  color: $Black;
  font-size: 24px;
  font-weight: 700;
  //margin: 0 0 $Margin 0;
  padding: 10px 0;
  line-height: 110%;
}

h5 {
  color: $Black75;
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 $Margin 0;
  padding: 5px 0;
}

h6 {
  color: $Black75;
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 $Margin 0;
  line-height: 110%;
}

a {
  color: $Black;
  text-decoration: none;
  position: relative;
  display: inline-block;
  width: auto;
  // &::after {
  //     content: "";
  //     bottom: 0;
  //     width: 100%;
  //     height: 2px;
  //     background-color: $Black;
  //     position: absolute;
  //     left: 0;
  // }
}

p {
    &_bold {
        font-weight: 400;
    }

    &.tanks {
      font-size: 16px;
      font-weight: 600;
      padding-bottom: 4px;
      margin-left: 8px;
    }

}

.alert_no_info {
  // text-align: center;
  font-weight: 500;
  font-size: 16px;
  margin-left: 16px;
}

.red_card {
  @include mixins.card($glass: false);
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &_primary_content {
    width: 100%;
  }

}

.main_container {
  background: linear-gradient(
      247.51deg,
      rgba(198, 227, 225, 0.2) 13.76%,
      rgba(79, 23, 31, 0.2) 94.3%
    ),
    url("../../public/hero.jpg");
  min-height: 100vh;
  width: 100vw;
  margin: 0;
  background-size: cover;
  position: relative;
  padding: 16px;
  height: 100%;
}

.primary_container {
  padding: 32px 80px 32px 32px;
  position: relative;
  // width: 100%;
  
  // width: calc(100% - 322px);

  /* Large or high resolution screen */
  @media all and (max-width: 1690px) {

  }

  /* Desktop or laptop */
  @media all and (min-width: 1280px) and (max-width: 1689px) {

  }

  // /* Tablet in horizontal view */
  // @media all and (min-width: 737px) and (max-width: 1279px) {
  // }

  // /* Tablet in vertical view */
  // @media all and (min-width: 481px) and (max-width: 736px) {
  // }

  /* Smartphone or small tablet */
  @media all and (max-width: 480px) {
    position: unset;
    width: 100%;
    padding: 0px 22px 22px 22px;
    margin-top: 20px;
    position: relative;
    top: 20px;
    left: 0;


    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

}

.react-datepicker-popper {
  z-index: 1000;
}

.fg1 {
  flex-grow: 1;
}

.fg0 {
  flex-grow: 0;
}

.textarea_field {
  padding: 0 0px;
}
.submit_field input {
  background-color: rgba(100, 36, 46, 0.5);
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: $ShadowGray;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  // background: orange;
}

*::-webkit-scrollbar-thumb {
  background-color: $ShadowGray;
  border-radius: 20px;
  // border: 3px solid orange;
}