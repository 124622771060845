@use "../../../styles/variables";

.primary_container {
  top: 50px;
  left: 320px;
  width: calc(100% - 340px);

  .redirect_wrapper {
    display: flex;
    align-items: baseline;
    gap: 20px;
  }

  &_menu {
    /* Large or high resolution screen */
    @media all and (max-width: 1690px) {
    }

    /* Desktop or laptop */
    @media all and (min-width: 1280px) and (max-width: 1689px) {
    }

    // /* Tablet in horizontal view */
    // @media all and (min-width: 737px) and (max-width: 1279px) {
    // }

    // /* Tablet in vertical view */
    // @media all and (min-width: 481px) and (max-width: 736px) {
    // }

    /* Smartphone or small tablet */
    @media all and (max-width: 480px) {
      // order: 1;
      flex-grow: 1;
      min-width: 115px;
    }
  }

  &_wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  &_upper {
    display: flex;
    gap: 32px;

    /* Large or high resolution screen */
    @media all and (max-width: 1690px) {
    }

    /* Desktop or laptop */
    @media all and (min-width: 1280px) and (max-width: 1689px) {
    }

    // /* Tablet in horizontal view */
    @media all and (min-width: 737px) and (max-width: 1279px) {
      // flex-direction: column;
      // min-width: 820px;
    }

    // /* Tablet in vertical view */
    @media all and (min-width: 481px) and (max-width: 736px) {
      // flex-direction: column;
    }

    /* Smartphone or small tablet */
    @media all and (max-width: 480px) {
      flex-direction: column;
    }

    &_info {
      width: 100%;
      h2 {
        margin-bottom: 8px;
      }
      a {
        width: fit-content;
      }

      &_row {
        display: flex;
        gap: 16px;
      }
    }
  }
  &_row {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
    align-items: center;

    h2 {
      margin-bottom: 0;
    }

    /* Large or high resolution screen */
    @media all and (max-width: 1690px) {
    }

    /* Desktop or laptop */
    @media all and (min-width: 1280px) and (max-width: 1689px) {
    }

    /* Tablet in horizontal view */
    @media all and (min-width: 737px) and (max-width: 1279px) {
    }

    /* Tablet in vertical view */
    @media all and (min-width: 481px) and (max-width: 736px) {
    }

    /* Smartphone or small tablet */
    @media all and (max-width: 480px) {
      flex-wrap: wrap;

      .warning_wrapper {
        flex-wrap: nowrap;
      }
    }

    &.ctas {
      justify-content: flex-end;

      /* Large or high resolution screen */
      @media all and (max-width: 1690px) {
      }

      /* Desktop or laptop */
      @media all and (min-width: 1280px) and (max-width: 1689px) {
      }

      /* Tablet in horizontal view */
      @media all and (min-width: 737px) and (max-width: 1279px) {
      }

      /* Tablet in vertical view */
      @media all and (min-width: 481px) and (max-width: 736px) {
      }

      /* Smartphone or small tablet */
      @media all and (max-width: 480px) {
        justify-content: unset;
      }
    }
  }
}

.next_operations_wrapper {
  height: 200px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  &_operation {
    div {
      width: 150px;

      /* Smartphone or small tablet */
      @media all and (max-width: 480px) {
        width: auto;
      }
    }
    .expand-width {
      flex-grow: 2;
    }

    .priority {
      width: 80px;
    }

    display: flex;
    gap: 16px;
    padding: 8px 4px;
    position: relative;
    width: 100% !important;
    justify-content: space-between;
    border-radius: 4px;
    &:hover {
      background-color: variables.$GlassLightBlue50;
      &.light_gray {
        background-color: variables.$CardLightGray25;
      }
    }
    &::after {
      content: "";
      position: absolute;
      width: calc(100% - 12px);
      left: 50%;
      transform: translate(-50%);
      height: 1px;
      background-color: variables.$Black50;
      bottom: 0;
    }
    &:last-child {
      &::after {
        height: 0;
        content: none;
      }
    }
  }
}

.legend {
  margin-left: auto;
  text-align: right;

  ul {
    margin-top: -20px;
    li {
      margin-bottom: 10px;

      .icon {
        display: inline-block;
        width: 20px;
        text-align: center;
        margin-left: 5px;

        &.red {
          background-color: variables.$RedWine;
          padding: 3px;
          padding-top: 4px;
          padding-right: 3px;
          border-radius: 50%;
        }
      }

      .operation {
        border-right: 6px solid variables.$AddRowGreen;

        &.expired {
          border-right: 6px solid variables.$RedExpireDateCard;
        }
      }
    }
  }
}

.analysis_grid {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  // justify-content: space-between;

  .p-long-tanks {
    font-weight: 500;
    text-align: center;
  }
}

.analysis_tile {
//   width: 350px;
  width: calc(100% / 3 - 30px);
  height: 280px;
  background-color: variables.$CardLightGray25;
  position: relative;
  z-index: 1;
  backdrop-filter: variables.$Blur;
  box-shadow: variables.$CardShadow;
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow variables.$TransitionTime variables.$TransitionMode;
  border-radius: variables.$CardRadius;
  padding: variables.$Padding;

  // border: 2px solid variables.$LightBlue;
  background-color: variables.$GlassLightBlue25;
  // background: variables.$GlassLightBlue50;
  user-select: none;

  &.is_mouse_down {
    cursor: grab;
  }

  &_progress {
    position: absolute;
    bottom: 0;
    background: variables.$RedWine;
    width: 100%;
    left: 0;
    border-bottom-left-radius: variables.$ButtonRadius;
    border-bottom-right-radius: variables.$ButtonRadius;
    z-index: 0;
  }
  &_content {
    position: relative;
    z-index: 1;
    // background: variables.$White;
    border-radius: variables.$ButtonRadius;
    padding: 8px;
    // box-shadow: variables.$ShadowInsetCoord variables.$ShadowGray inset;
    transition: box-shadow variables.$TransitionTime variables.$TransitionMode;

    &_name {
      font-weight: 600;
      margin-bottom: 4px;
      padding-bottom: 4px;
      font-size: 18px;
      border-bottom: 1px solid variables.$Black50;
      width: 100%;

      &_wrapper {
        display: inline-block;
        display: flex;

        &_icon {
          margin-left: 14px;
          margin-top: -4px;
        }
      }
    }

    &_tanks {
    //   flex: 1 1 33%;
      // flex: 0 0 auto;
        display: inline-block;
        // width: 200px;
        // height: 100px;

      &.overflow_x {
        // flex: 0 0 auto;
        // width: auto;
        // max-width: 100%;
        // margin-right: 10px;
        // margin-bottom: 20px;
        // width: 150px;
      }

      &_container {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        margin-bottom: 10px;
        justify-content: space-evenly;
        align-items: flex-end;

        .slider_container {
            display: flex;
            flex-direction: row;
            gap: 16px;
            align-items: center;
        }

        &.overflow_x {
          flex-wrap: nowrap;
          overflow-x: auto;
          overflow-y: hidden;
          justify-content: unset;
          overflow-x: auto;
          overflow-y: hidden;
          margin-left: 10px;
        }

        .tank_name {
          font-size: 15px;
          font-weight: 600;
          // margin-left: 16px;
          text-align: center;
          display: flex;
          justify-content: center;
        }

        .tank_quantity {
          font-size: 15px;
          font-weight: 400;
          // margin-left: 16px;
          display: flex;
          justify-content: center;
        }
      }

      .init_status {
        text-align: center;
        width: 180px;
      }

      .end_status {
        margin-top: 10px;
        margin-left: 16px;
        text-align: center;
        width: 180px;
      }
    }

    &_lots {
      margin-bottom: 10px;
    }

    &_batch {
      font-weight: 400;
      margin-bottom: 4px;
      padding-bottom: 4px;
    }
  }

  &:hover {
    box-shadow: variables.$HoverShadowCoord variables.$ShadowGray;
    .analysis_tile_content {
      //   box-shadow: variables.$HoverShadowInsetCoord variables.$ShadowGray inset;
    }
  }
}
