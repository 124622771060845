@use "../../../styles/variables";
@use "../../../styles/mixins";

.privacy_wrapper {
  background-color: variables.$White;
  padding: 32px;

  h5 {
    margin-top: 40px;
    margin-bottom: -5px;
  }

  .privacy_header {
    line-height: 1.25em;
  }

  .privacy_content {
    line-height: 1.25em;

    .inner_ul {
      list-style: disc;
      margin-left: 20px;
    }

    span {
      font-weight: 600;
      margin-right: 20px;
    }

    .link {
      cursor: pointer;
      color: variables.$RedWine;
      margin-left: 10px;
    }

    .column {
      margin-top: 10px;
    }
    
    .li {
      margin-top: 20px;
      
      &.no_margin {
        margin-top: 0px;
      }
    }
  }
}
