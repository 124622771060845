@use '../../../../styles/variables';

.base_dropdown {
    text-align: center;
    width: fit-content;
    position: relative;
    color: variables.$Black;
    padding: 16px 16px;
    border: none;
    background-color: variables.$Cta15;
    box-shadow: variables.$CardShadow;
    font-size: 14px;
    border-radius: 100px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 500;
    
    &.new_operation {
        background-color: variables.$CtaNewOperation;
        font-size: 18px;
        width: 45%;
        padding: 21px 20px;
   
        /* Large or high resolution screen */
        @media all and (max-width: 1690px) {

        }

        /* Desktop or laptop */
        @media all and (min-width: 1280px) and (max-width: 1689px) {

        }

        // /* Tablet in horizontal view */
        // @media all and (min-width: 737px) and (max-width: 1279px) {
        // }

        // /* Tablet in vertical view */
        // @media all and (min-width: 481px) and (max-width: 736px) {
        // }

        /* Smartphone or small tablet */
        @media all and (max-width: 480px) {
            width: 65%;
            padding: 20px 19px;
        }
    }

    transition: box-shadow variables.$TransitionTime variables.$TransitionMode, 
            background variables.$TransitionTime variables.$TransitionMode,
            color variables.$TransitionTime variables.$TransitionMode;
    &:hover {
        background-color: variables.$RedWine75;
        color: variables.$White;
        // box-shadow: variables.$CardShadow;
        box-shadow: variables.$HoverShadowCoord variables.$ShadowGray;
        
        &.new_operation {
            background-color: variables.$CtaNewOperationHover;
            color: variables.$Black;
        }
    }
    &_list {
            max-height: 220px;
            overflow-x: hidden;
            overflow-y: auto;
            position: absolute;
            top: 90%;
            // background: variables.$GlassLightBlue50;
            background: variables.$CardLightGray35;
            padding: 10px 16px;
            left: 16px;
            z-index: 10;
            border-radius: 8px;
            backdrop-filter: variables.$Blur;
            display: flex;
            flex-direction: column;
            // border: 2px solid variables.$LightBlue;
            min-width: 200px;
            box-shadow: variables.$CardShadow;
            color: variables.$Black;
            &_option {
                padding: 8px;
                cursor: pointer;
                border-radius: 8px;
                position: relative;
                color: variables.$Black;
                &:hover {
                color: variables.$Black;
                background-color:  variables.$GlassLightBlue50;
            }
            &::after {
                position: absolute;
                bottom: 0;
                content: "";
                width: calc(100% - 12px);
                left: 50%;
                transform: translate(-50%);
                height: 1px;
                background-color: variables.$Black50;
            }
            &:last-child {
                &::after {
                    height: 0;
                    content: none;
                }
            }
        }
    }
}