@use '../../../../styles/variables';

.info_card_container {
    z-index: 1;
    .info_card {
        border-radius: 4px;
        // box-shadow: variables.$CardShadow;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 16px;
        font-weight: 600;
        min-width: 200px;
        background-color: variables.$CardLightGray25;
        width: 100%;
    
        // &:hover {
            // box-shadow: variables.$HoverShadowCoord variables.$ShadowGray;
        // }

        .info_card_label {
            width: 75%;
            display: flex;
            gap: 16px;
            align-items: center;
        }
    
        .info_card_number {
            font-size: 22px;
            margin-left: 10px;
            display: flex;
            gap: 8px;
            align-items: center;

            img {
                width: 50px;
                background-color: variables.$White;
                border-radius: 50%;
                padding: 3px;

                &.grey {
                    filter: gray;
                    -webkit-filter: grayscale(1);
                    filter: grayscale(1);
                }
            }
        }
    }
}