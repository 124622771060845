@use "../../../styles/variables";

.button-margin {
  margin-top: 20px;
  margin-bottom: 20px;
}

.lot_info_container {

  .panel_wrapper {
    display: flex;
    gap: 16px; /* Optional spacing between panels */
    
    .left_panel {
      flex: 2;
    }
    
    .right_panel {
      flex: 1;
    }
    
    /* Hide right_panel if it's not present */
    .right_panel:empty {
      display: none;
    }
    
    .lot_info_container_row {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      .tank {
        // width: 100px;

        .tank_name {
          font-weight: 600;
        }
      }

      &.no_wrap {
        flex-wrap: nowrap;
      }

      &.space_evenly {
        justify-content: space-evenly;
        flex-wrap: nowrap;
      }

      .lot_wine_wrapper {
        position: relative; // Establish positioning context
        display: inline-block; // Prevent full width
      
        .cta_switch {
          position: absolute;
          top: 7px; // Moves it slightly below the box
          right: -25px; // Moves it slightly to the right, outside the box
          cursor: pointer;
          transition: transform 0.2s ease;
          z-index: 2; // Keeps it above the box

          .cta {
            padding: 5px 9px !important;
            margin-top: 6px;
            margin-bottom: 6px;

            &.on {
              background-color: variables.$RedWine;
              color: variables.$White;
            }

            &:hover {
              transform: scale(1.1);
            }
          }
        }

        .lot_wine_label {
          border: 2px solid variables.$RedWine;
          border-radius: 8px;
          padding: 16px;
          min-width: 250px;
          max-width: 350px;
          margin-left: 16px;
          background: variables.$WineLabel;
          color: variables.$RedWine;
          position: relative; // Ensures proper stacking order
          z-index: 3; // Keeps it above other elements
          
          .wine_labels {
            &_title {
              text-align: center;
              font-size: 1.5rem;
              margin-bottom: 12px;
              color: #8b0000;
            }
        
            &_ul {
            list-style: none;
            padding: 0;
            margin: 0;
        
              &_li {
                display: flex;
                justify-content: space-between;
                padding: 8px 0;
                border-bottom: 1px solid #ccc;
          
                &:last-child {
                  border-bottom: none;
                }
          
                &_name {
                  font-weight: bold;
                }
          
                &_amount {
                  margin-left: 20px;
                  font-style: italic;
                }
              }
            }
          }
        }   
      } 
    }

  }

  .tank_name {
    font-size: 15px;
    font-weight: 600;
    // margin-left: 16px;
    display: flex;
    justify-content: center;
  }

  .tank_quantity {
    font-size: 15px;
    font-weight: 400;
    // margin-left: 16px;
    display: flex;
    justify-content: center;
  }
}

// .init_status {
//   text-align: center;
//   width: 180px;
// }

// .end_status {
//   text-align: center;
//   width: 180px;

//   &.unset-width {
//     width: unset;
//   }

//   .no_name {
//     margin-top: 57px;
//     // margin-left: 16px;
//   }

//   .centered {
//     text-align: center;
//   }
// }

.tank_header {
  font-weight: 500;
  margin-bottom: 30px;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;

  p {
    width: 150px;
    border-bottom: 1.5px solid variables.$RedWine;
  }
}

// .tank_representation {
//   width: 40px;
//   height: 60px;
//   background: variables.$GlassLightBlue;
//   background: variables.$CardLightGray50;
//   position: relative;
//   border-radius: 8px;
//   overflow: hidden;
//   margin-bottom: 8px;
//   // margin-top: 10px;
//   // margin-left: 16px;

//   display: flex;
//   margin: 10px auto;
//   max-width: 40px;
//   margin-bottom: 10px;

//   &.barrel {
//     // width: 35px;
//     // max-width: 35px;
//     // height: 25px;
//     // display: block;
//     // background-color: variables.$LightWood25;
//     border-radius: 100% / 20%;

//     background: repeating-linear-gradient(
//       0deg,
//       variables.$LightWood25 5px,
//       variables.$DarkWood35 5px,
//       variables.$LightWood25 8px,
//       variables.$LightWood25 30px
//     );

//     // &.tonneau {
//     //     width: 58px;
//     //     max-width:580px;
//     //     height: 38px;
//     //     background: repeating-linear-gradient(
//     //         90deg,
//     //         variables.$LightWood25 5px,
//     //         variables.$DarkWood35 5px,
//     //         variables.$LightWood25 9px,
//     //         variables.$LightWood25 27px
//     //     );
//     // }
//   }

//   .tank_tile_progress {
//     position: absolute;
//     bottom: 0;
//     background: variables.$RedWine;
//     width: 100%;
//     left: 0;
//     border-bottom-left-radius: variables.$ButtonRadius;
//     border-bottom-right-radius: variables.$ButtonRadius;
//     z-index: 0;

//     &_white {
//       position: absolute;
//       bottom: 0;
//       width: 100%;
//       left: 0;
//       border-bottom-left-radius: variables.$ButtonRadius;
//       border-bottom-right-radius: variables.$ButtonRadius;
//       z-index: 0;
//       background: variables.$WhiteWine;
//     }
//     &_rose {
//       position: absolute;
//       bottom: 0;
//       width: 100%;
//       left: 0;
//       border-bottom-left-radius: variables.$ButtonRadius;
//       border-bottom-right-radius: variables.$ButtonRadius;
//       z-index: 0;
//       background: variables.$RoseWine;
//     }

//     &_red {
//       position: absolute;
//       bottom: 0;
//       width: 100%;
//       left: 0;
//       border-bottom-left-radius: variables.$ButtonRadius;
//       border-bottom-right-radius: variables.$ButtonRadius;
//       z-index: 0;
//       background: variables.$RedWine;
//     }
//   }

//   &_recap {
//     border: 1px solid variables.$Black50;
//   }
// }

.lot_representation {
  width: 100px;
  height: 35px;
  border: 3px solid variables.$LightWood25;
  // border: 3px solid variables.$LighterGray;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 8px;
  // margin-top: 10px;
  // margin-left: 16px;

  display: flex;
  margin: 10px auto;
  max-width: 100px;
  margin-bottom: 10px;
  border-top: none;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;

  &_recap {
    border: 1px solid variables.$Black50;
  }

  .lot_progress_white {
    position: absolute;
    bottom: 0;
    background: variables.$WhiteWine;
    width: 100%;
    left: 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    z-index: 0;
  }

  .lot_progress_rose {
    position: absolute;
    bottom: 0;
    background: variables.$RoseWine;
    width: 100%;
    left: 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    z-index: 0;
  }

  .lot_progress_red {
    position: absolute;
    bottom: 0;
    background: variables.$RedWine;
    width: 100%;
    left: 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    z-index: 0;
  }
}

// .tank_name {
// font-weight: 600;
// margin-left: 16px;
// }
