@use "../../../styles/variables";

.card_filters {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 16px;
  align-items: baseline;
  margin-left: auto;

  .card_filter {
    width: calc(100% / 7);
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 16px;
    font-weight: 600;
    min-width: 150px;
    box-shadow: variables.$CardShadow;
    // box-shadow: 0 8px 8px -4px variables.$RedWine;
    border-radius: 15px;

    &.is_filter {
      background-color: variables.$RedWine25;
    }

    &:hover {
      cursor: pointer;
      box-shadow: variables.$HoverShadowCoord variables.$ShadowGray;
    }
  }

  .search_wrapper {
    min-width: 390px;
    margin-right: 20px;
    
    .input-search {
      height: 50px;
      width: 100%;
      border-style: none;
      padding: 10px;
      font-size: 18px;
      letter-spacing: 2px;
      outline: none;
      padding-right: 40px;
      color: variables.$Black;

      border-radius: 0px;
      background-color: transparent;
      border-bottom: 1px solid variables.$RedWine;
      
      &::placeholder {
        color: variables.$RedWine;
        font-size: 15px;
        letter-spacing: 2px;
        font-weight: 400;
      }
    }
  }

  .dropdown_filters {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 16px;
    align-items: center;

    .dropdown_filter {
      position: relative;
      display: inline-block;
      width: 100%;

      .dropdown-filter-content {
        position: absolute;
        background-color: variables.$White;
        min-width: 160px;
        padding: 10px 5px;
        border-radius: 5px;
        border-top-right-radius: 0px;
        width: fit-content;
        box-shadow: variables.$CardShadow;
        right: 10px;
        top: -8px;
        display: none;
        z-index: 10;

        &.open {
          display: block;

          &:hover {
            cursor: pointer;  
          }
        }

        .range {
          input {
            width: 100%;
            margin-top: 5px;
          }
        }

        li {
          color: variables.$Black;
          padding: 12px 16px;
          text-decoration: none;
          display: block;
          font-weight: 400;

          &.all {
            border-bottom: 2px solid variables.$RedWine25;

            &.disabled {
              color: variables.$Black25;

              &:hover {
                cursor: default;
                background-color: variables.$White;
              }
            }
          }

          &.active {
            color: variables.$White;
            background-color: variables.$RedWine;
          }

          &:hover {
            color: variables.$White;
            background-color: variables.$RedWine50;
          }
        }
      }
    }
  }
}
