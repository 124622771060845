@mixin fontJost($FontPath, $Version, $FontType: "Regular") {
    src: url("#{$FontPath}/jost/Jost-#{$FontType}.ttf") format('truetype'),
}

@font-face {
    font-family: 'jost';
    @include fontJost($FontPath, $Version, "Thin");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'jost';
    @include fontJost($FontPath, $Version, "ThinItalic");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'jost';
    @include fontJost($FontPath, $Version, "ExtraLight");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'jost';
    @include fontJost($FontPath, $Version, "ExtraLightItalic");
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'jost';
    @include fontJost($FontPath, $Version, "Light");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'jost';
    @include fontJost($FontPath, $Version, "LightItalic");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'jost';
    @include fontJost($FontPath, $Version, "Regular");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'jost';
    @include fontJost($FontPath, $Version, "Italic");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'jost';
    @include fontJost($FontPath, $Version, "Medium");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'jost';
    @include fontJost($FontPath, $Version, "MediumItalic");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'jost';
    @include fontJost($FontPath, $Version, "SemiBold");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'jost';
    @include fontJost($FontPath, $Version, "SemiBoldItalic");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'jost';
    @include fontJost($FontPath, $Version, "Bold");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'jost';
    @include fontJost($FontPath, $Version, "BoldItalic");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'jost';
    @include fontJost($FontPath, $Version, "ExtraBold");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'jost';
    @include fontJost($FontPath, $Version, "ExtraBoldItalic");
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'jost';
    @include fontJost($FontPath, $Version, "Black");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'jost';
    @include fontJost($FontPath, $Version, "BlackItalic");
    font-weight: 900;
    font-style: italic;
}