@use '../../../../styles/variables';

.base_card {
    background-color: variables.$GlassLightBlue50;
    backdrop-filter: variables.$Blur;
    border-radius: variables.$CardRadius;
    box-shadow: variables.$CardShadow;
    padding: variables.$Padding;
    width: 100%;
    min-height: 64px;
    
    &.light_gray {
        background-color: variables.$CardLightGray25;
    }
}