@use "../../../../styles/variables";

// .timeline {
//   overflow: hidden;
//   padding: 10px 0;
// }

// .timeline-container {
//   position: relative;
//   padding: 50px 0;
//   // margin-left: 20px;

//   &::before {
//     // this is the timeline vertical line
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 50%;
//     height: 100%;
//     width: 4px;
//     background: variables.$RedWine;
//   }
// }

// .timeline-counter {
//   // position: absolute;
//   // top: -15px;
//   // left: 98%;
//   background: variables.$GlassLightBlue;
//   color: variables.$Black;
//   border-radius: 8px;
//   width: 20px;
//   height: 20px;
//   // display: flex;
//   // justify-content: center;
//   // align-items: center;
//   // z-index: 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-shrink: 0;
//   z-index: 1;
//   margin-left: 10px;

//   &.tooltip {
//     background: variables.$RedWine;
//     color: variables.$White;
//   }
// }

// .timeline-arrow {
//   display: flex;
//   order: 2; // flex order to do not shift the timeline-img operation block
//   margin-left: 5px;
//   margin-top: -10px;
//   height: fit-content;
  
//   &.in {
//     // color: variables.$GrapeWineGreen;
//     color: variables.$TimelineArrowGray; 
//     transform: rotate(315deg);
//   }

//   &.out {
//     // color: variables.$RedError55;
//     color: variables.$TimelineArrowGray;
//     transform: rotate(135deg);
//   }
// }

// .timeline-tag {
//   color: red;
//   font-weight: 600;
//   font-size: 12px;
//   display: flex;
//   order: 1; // flex order -> place the image after cd-timeline-content
//   margin-left: 0%;
//   margin-top: 10px;
//   text-transform: uppercase;

//   &.legend-item {
//     display: inline;
//     margin-left: 10px;
//   }
// }

// .timeline-block {
//   display: flex;
//   position: relative;
//   z-index: 1; // make sure content is above the timeline vertical line
//   margin-bottom: 50px;
//   cursor: pointer;
//   user-select: none;

//   &.operation {
//     margin-top: 20px;
//     margin-bottom: 20px;

//     &.no-click {
//       cursor: default;
//     }

//     // &:nth-child(even) {
//     //   flex-direction: row-reverse; // for even blocks -> lay out content from right to left
//     //   color: red;
//     // }
//   }

//   &.repetitive {
//     margin-top: 40px;
//     margin-bottom: 40px;
//   }

//   &:last-child {
//     // margin-top: 30px;
//     // margin-bottom: 0;
//   }

//   // @include breakpoint(md) {
//   //   &:nth-child(even) {
//   //     flex-direction: row-reverse; // for even blocks -> lay out content from right to left
//   //   }
//   // }
// }

// .timeline-img {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-shrink: 0;
//   z-index: 2;
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
//   box-shadow: 0 0 0 4px white, inset 0 2px 0 rgba(#000, 0.08),
//     0 3px 0 4px rgba(#000, 0.05);
//   background-color: variables.$RedWine;
//   color: variables.$White;

//   .timeline-icon {
//     &.operation {
//       // width: 24px;
//       // height: 24px;
//       margin-left: 10px;

//       &.reverse {
//         transform: rotate(180deg);
//       }
//     }
    
//     &.expand {
//       position: relative;
//       left: 10%;
//     }

//     &.expand-opened {
//       position: relative;
//       left: 18%;
//     }
//   }

//   // @include breakpoint(md) {
//   width: 40px;
//   height: 40px;
//   order: 1; // flex order -> place the image after cd-timeline-content
//   margin-left: calc(5% - 18px);
//   will-change: transform;

//   &.empty_tank_tile {
//     color: variables.$Black;
//     border: none;
//     box-shadow: none;
//     margin-left: 0px;
//     background-color: variables.$White;
//   }

//   &.empty_tank_tile_border {
//     border: 10px solid variables.$White;
//     margin-left: calc(50% - 17px);
//     box-shadow: none;
//   }

//   &.operation {
//     width: 200px;
//     height: 40px;
//     margin-left: calc(50% - 95px);
//     border-radius: 10px;
//     background-color: variables.$LighterBlue;
//     box-shadow: 0 0 0 2px variables.$LightBlue;
//     color: variables.$Black;
//     // border-color: variables.$GlassLightBlue;
//     // border: 2px solid variables.$LightBlue;

//     &.tooltip {
//       margin-left: calc(10% - 94.5px);
//       // background-color: variables.$Gold;
//       box-shadow: 0 0 0 2px variables.$DarkerBlue;
//     }

//     &.shifted {
//       margin-top: -20px;
//       margin-left: -191px;
//       z-index: 0;
//       opacity: 0.3;

//       &.second {
//         margin-top: -10px;
//         margin-left: -190px;
//         z-index: 1;
//         opacity: 0.4;
//       }
//     }

//     &.start,
//     &.end {
//       background-color: variables.$White;
//       box-shadow: none;
//       font-size: 15px;
//       font-weight: 500;
//       // background-color: variables.$Lighter2Gray;
//       // box-shadow: 0 0 0 2px variables.$LightGray;
//       border-radius: 80px;
//       width: 160px;
//       height: 40px;
//       margin-left: calc(50% - 76px);

//       &.start {
//         margin-bottom: -40px;
//       }

//       &.end {
//         margin-top: -50px;
//       }
//     }

//     &.new-batch-name {
//       // background-color: variables.$RoseWine;
//       box-shadow: 0 0 0 2px variables.$LighterGray;
//       border-radius: 80px;
//       width: 140px;
//       max-height: 40px;
//       margin-left: calc(50% - 66px);
//       margin-top: 20px;
//       margin-bottom: 20px;
//       padding: 7px;
//       font-size: 15px;
//       color: variables.$White;
//       text-align: center;

//       &.color_red {
//         background-color: variables.$RedWine;
//         box-shadow: 0 0 0 2px variables.$LightRedWine;
//       }

//       &.color_white {
//         color: variables.$Black;
//         background-color: variables.$WhiteWine;
//         box-shadow: 0 0 0 2px variables.$Gold;
//       }

//       &.color_rose {
//         background-color: variables.$RoseWine;
//         box-shadow: 0 0 0 2px variables.$RedError;
//       }
//     }

//     &.empty_tank_tile {
//       // background-color: variables.$RoseWine;
//       box-shadow: 0 0 0 2px variables.$LighterGray;
//       border-radius: 80px;
//       width: 140px;
//       max-height: 40px;
//       margin-left: calc(50% - 66px);
//       margin-top: 20px;
//       margin-bottom: 20px;
//       padding: 7px;
//       font-size: 15px;
//       color: variables.$White;
//       text-align: center;
//     }
//   }

//   timeline-block:nth-child(even) & {
//     margin-right: calc(5% - 18px);
//   }

//   // }
// }

// .timeline-content {
//   flex-grow: 1; // expand element so that it takes up all the available space inside its parent
//   position: relative;
//   margin-left: 20px;
//   padding: 15px;

//   background: variables.$GlassLightBlue20;
//   backdrop-filter: variables.$Blur;
//   box-shadow: variables.$CardShadow;
//   // overflow: hidden;
//   cursor: pointer;
//   transition: box-shadow variables.$TransitionTime variables.$TransitionMode;
//   border-radius: variables.$CardRadius;
//   border: 2px solid variables.$LightBlue;

//   &::before {
//     // triangle next to content block
//     content: "";
//     position: absolute;
//     top: 8px;
//     left: 100%;
//     z-index: 1;
//     // @include triangle(left, 7px, var(-color-white));
//     width: 0;
//     height: 0;
//     border-top: 12px solid transparent;
//     border-left: 15px solid variables.$GlassLightBlue;
//     border-bottom: 12px solid transparent;
//   }

//   // @include breakpoint(md) {
//   width: 40%;
//   flex-grow: 0; // prevent element from growing inside its parent
//   will-change: transform;
//   margin: 0;
//   font-size: 0.8em;
//   -line-height-multiplier: 1.2;

//   .tooltip {
//     // position: absolute;
//     width: 100%;
//     // left: 45%;
//     font-size: 17px;

//     span {
//       font-weight: 600;
//       flex: auto;
//     }

//     .date {
//       top: 13px;
//     }

//     .user {
//       margin-top: 10px;
//       display: flex;

//       .additional {
//         justify-content: flex-end;
//         display: flex;
//         flex-wrap: wrap;
//         max-width: 80%;

//         .par {
//           width: 100%;
//           text-align: end;
//           margin-bottom: 0px;

//           .bold {
//             font-weight: 500;
//           }
//         }

//       }

//       p:not(:last-child) {
//         margin-bottom: 10px;
//         text-align: end;
//       }
//     }

//     .result {
//       margin-top: 10px;
//       // display: flex;
//       font-weight: 400;
//     }

//     .result span {
//       // font-weight: 500;
//       // display: inline-block;
//       // width: 80px;
//     }

//     .result-list {
//       // margin-left: 35px;
//       // list-style: initial;
//     }
//   }

//   &::before {
//     // triangle
//     top: 8px;
//   }

//   //   timeline-block:nth-child(odd) &::before { // change triangle direction
//   //     right: auto;
//   //     left: 100%;
//   //     @include triangle(right, 7px, var(-color-white));
//   //   }
//   // }
// }

// .timeline-date {
//   font-size: 18px;
//   position: absolute;
//   width: 100%;
//   left: 125%;
//   top: 12px;

//   &.operation {
//     left: 26%;
//     color: variables.$LightGray;

//     &.reverse {
//       font-size: 20px;
//       font-weight: 500;
//       left: 63%;
//     }
//   }

//   timeline-block:nth-child(even) & {
//     left: auto;
//     right: 125%;
//     text-align: right;
//   }
//   // }
// }

// .timeline-legend {
//   margin-top: 50px;
//   margin-left: auto;
//   text-align: right;

//   ul {
//     margin-top: -20px;
//   }

//   li {
//     margin-bottom: 5px;
//   }
// }

// .legend-span {
//   margin-top: 0px;
//   margin-bottom: 0px;
  
//   .legend-item {
//     background-color: rgb(199, 79, 89);
//     box-shadow: 0 0 0 2px rgb(67 66 62);
//     border-radius: 80px;
//     margin-left: 10px;
//     padding: 0px 15px;
//     font-size: 10px;
//     color: #f3eff5;
//   }

//   &.legend-arrow {
//     display: inline-block;
//     height: fit-content;
//     margin-left: 5px;
//     color: variables.$TimelineArrowGray;
    
//     &.in {
//       // color: variables.$GrapeWineGreen;
//       transform: rotate(315deg);
//     }
  
//     &.out {
//       // color: variables.$RedError55;
//       transform: rotate(135deg);
//     }
//   }
// }

.node_wrapper {
  position: relative;

//   .node_wrapper_treatment {
//     display: flex;
//     justify-content: center;
//     padding: 5px;
//     margin-bottom: 10px;
//   }

  &_header {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    text-decoration: underline;
    margin-bottom: 10px;

    &.color {
      &_red {
        text-decoration-color: variables.$RedWine;
      }
      &_rose {
        text-decoration-color: variables.$RoseWine;
      }
      &_white {
        text-decoration-color: variables.$WhiteWine;
      }
    }
  }
}

// // @include breakpoint(md) { // animations
// //   timeline-img--hidden, timeline-content--hidden {
// //     visibility: hidden;
// //   }

// //   timeline-img-bounce-in {
// //     animation: cd-bounce-1 0.6s;
// //   }

// //   timeline-content-bounce-in {
// //     animation: cd-bounce-2 0.6s;
// //   }

// //   timeline-block:nth-child(even) timeline-content-bounce-in {
// //     animation-name: cd-bounce-2-inverse;
// //   }
// // }

// // @keyframes cd-bounce-1 {
// //   0% {
// //     opacity: 0;
// //     transform: scale(0.5);
// //   }
// //   60% {
// //     opacity: 1;
// //     transform: scale(1.2);
// //   }
// //   100% {
// //     transform: scale(1);
// //   }
// // }

// // @keyframes cd-bounce-2 {
// //   0% {
// //     opacity: 0;
// //     transform: translateX(-100px);
// //   }

// //   60% {
// //     opacity: 1;
// //     transform: translateX(20px);
// //   }

// //   100% {
// //     transform: translateX(0);
// //   }
// // }

// // @keyframes cd-bounce-2-inverse {
// //   0% {
// //     opacity: 0;
// //     transform: translateX(100px);
// //   }

// //   60% {
// //     opacity: 1;
// //     transform: translateX(-20px);
// //   }

// //   100% {
// //     transform: translateX(0);
// //   }
// // }
