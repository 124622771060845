@use "../../../../styles/variables";

.modal_wrapper {
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3); /* Optional dark overlay */
    backdrop-filter: blur(10px);
    z-index: 1000;
  }

  .base_modal {
    &::backdrop {
      background: transparent;
      cursor: not-allowed;
      // backdrop-filter: variables.$Blur;
    }

    .modal_container {
      width: 100%;
    }

    backdrop-filter: variables.$Blur;
    border-radius: variables.$CardRadius;
    // border: 2px solid variables.$LightBlue;
    // border: 2px solid variables.$CardLightGray50;
    // background-color: variables.$GlassLightBlue;
    background-color: variables.$CardLightGray25;
    border: none;
    box-shadow: variables.$CardShadow;
    padding: variables.$Padding;
    padding-bottom: 4px;
    min-height: 30%;
    max-height: 50%;

    width: 30%;
    min-width: 30%;
    max-width: 60%;
    overflow: auto;

    &.winemaking {
      background: variables.$White;
      //   border: 3px solid variables.$RedWine;
      border-radius: 8px;
      // padding: 16px 32px;
      padding: variables.$Padding;
      backdrop-filter: none;
      width: 80%;
      max-width: 80%;
      // height: 70%;
      min-height: 60%;
      max-height: 80%;

      .panel_wrapper {
        display: flex;
        gap: 16px; /* Optional spacing between panels */

        .left_panel {
          flex: 3;
        }

        .right_panel {
          flex: 2;

          .note_wrapper {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            margin-top: 18px;
          }
        }

        /* Hide right_panel if it's not present */
        .right_panel:empty {
          display: none;
        }
      }
    }

    &.is_winery_selector {
      width: 80%;
      height: 60%;
    }

    h4 {
      margin-bottom: 32px;
    }

    &.confirmation {
      // background-color: variables.$GlassLightBlue25;

      &.recap {
        // min-height: 60%;
        max-height: 90%;
        width: 60%;
        overflow: auto;
        // min-width: 60%;
        // max-width: 0%;
      }
    }
  }

  .confirmation-modal-container {
    white-space: pre-line;

    .rows {
      margin-top: 12px;

      h5 {
        margin-top: 10px;
        margin-bottom: -15px;
        color: variables.$RedWine;
      }

      .row_wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        align-items: baseline;

        // border: 1px solid variables.$RedWine;
        // background-color: #eae1d030;
        // border-radius: 12px;
        // padding: 8px;

        .textarea_field {
          width: 100%;
        }

        .label_field {
          margin-bottom: 0;
        }

        .substance_form_wrapper_rows {
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding-bottom: 16px;

          .substance_form_wrapper_row {
            display: flex;
            flex-direction: row;
            gap: 16px;
            align-items: center;

            &.fields {
              // margin-top: 10px;
              margin-bottom: -30px;
            }
          }

          .plus_button {
            margin-top: 20px;
          }

          .minus_button {
            flex-wrap: wrap;
            justify-content: center;
            height: -moz-fit-content;
            height: 31px;
            margin-top: 20px;
            display: flex;
            align-content: center;
          }
        }
      }
    }
    .login_title {
      margin-bottom: 0;
    }

    .login_wrapper {
      display: flex;
      gap: 16px;
    }

    .operation_info_container_row {
      align-items: center;
      // margin-top: -20px;

      &.notes {
        flex-direction: column;
        flex-grow: 1;
        gap: 0px;

        .textarea_field {
          margin: 1px;
          width: 100%;
          min-width: 150px;

          textarea {
            height: 100px;
          }
        }
      }
    }

    .description {
      // margin: 5%;
    }

    .description2 {
      margin-top: 12px;
    }

    .button-container {
      margin-top: 20px;
      display: flex;
      gap: 16px;
      align-items: center;
      // align-items: flex-start;
      justify-content: space-between;
    }
  }
}
