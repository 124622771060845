@use "../../../../styles/variables";

.carousel {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  overflow: hidden;
}

.carousel-wrapper {
  display: flex;
  justify-content: center;
  transition: transform 0.5s ease;
  width: 100%;
  align-items: center;
}

.carousel-container {
  position: relative;
  //   overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 25px;
  margin: 0 5px;
}

.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1; /* Add this to make the item take up equal space */
}

.carousel-button {
  max-width: 40px;
  position: absolute;
  z-index: 20;
  color: variables.$RedWine50;
  border: none;
  cursor: pointer;
  padding: 10px;
  margin: 2px;

  &.left {
    left: 10px;
  }

  &.right {
    right: 10px;
  }
}
