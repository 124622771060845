@use '../../../styles/variables';

.tank_settings {
    display: flex;
    gap: 16px;

    button {
        min-width: fit-content;
    }
}

.tank_info_wrapper {

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;

    .tank_info_container {
        width: 100%;
        margin-bottom: 32px;
        flex-grow: 3;

        &_row {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;

            .lot-info-opener {
                width: 180px;
                // margin-top: 16px;
                // margin-left: 32px;

                .lot_arrow {
                    cursor: pointer;

                    &.open {
                        transform: rotate(180deg);
                    }
                }

            }

            .current_tank {
                margin-top: 5px;
            }

            .tank_name {
                font-weight: 600;
            }

            .tank {
                width: 150px;
            }

            .sensors_wrapper {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 16px;
                margin-top: 16px;
            }
        }
    }

    .tank_lot_container {
        margin-top: 46px;
        margin-bottom: 32px;
        flex-grow: 1;
        display: none;
        height: fit-content;
        padding: 15px 15px 15px 15px;
        // background-color: variables.$White75;
        background-color: variables.$CardLightGray25;
        border-radius: 15px;
        box-shadow: variables.$CardShadow;


        &.open {
            display: block;
            max-width: 45%;
        }
    }
}
