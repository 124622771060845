@use "../../../styles/variables";

.slider-range {
  position: relative;
  width: 100%;
  min-width: 150px;
  height: 3px;
  background-color: #e2bebe; // Background of the slider
  border-radius: 5px;
  margin: 25px 0;
  margin-bottom: 15px;
  margin-top: 22px;

  .range {
    position: absolute;
    height: 100%;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    background-color: variables.$LighterGray; // When current value is within range
  }

  .current-value {
    position: absolute;
    top: -25px; // Position above the range
    padding: 2px 5px;
    border-radius: 5px;
    white-space: nowrap; // Prevent text wrapping
    transition: left 0.3s ease; // Smooth movement
    z-index: 10;
    font-weight: 500;
    color: variables.$LightGray;

    &.invalid {
      color: variables.$RedError;
    }
  }

  .current-point {
    width: 4px;
    height: 15px;
    position: absolute;
    background-color: variables.$LightGray;
    border: 1px solid #ccc;
    border-radius: 5px;
    white-space: nowrap; // Prevent text wrapping
    transition: left 0.3s ease; // Smooth movement
    margin-top: -6px;

    &.invalid {
      background-color: variables.$RedError;
    }
  }

  .min-max {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: -20px; // Position below the range
  }

  .min, .max {
    font-size: 13px; // Font size for min/max labels
    color: variables.$LightGray;
  }
}
