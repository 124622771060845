@use '../../../styles/variables';
.operation_form_wrapper {

    .arrow {
        width: 70px;
        text-align: center;
    }

    &_row {
        display: flex;
        gap: 16px;
        align-items: center;

        .button {
            margin-left: 10px;
        }
        
        .submit_field {
            margin-left: auto;
            input {
                background-color: variables.$RedWine50;
                &:hover {
                   background-color: variables.$RedWine75;
                    color: variables.$White;
                    box-shadow: variables.$CardShadow;
                }
                &:active {
                    background-color: variables.$RedWine;
                    color: variables.$White;
                    box-shadow: variables.$CardShadow;
                }
            }
        }

        &.vertical {
            flex-direction: column;
            align-items: baseline;
        }

        &.aside {
            row-gap: 50px;
            align-items: start;
        }

        .tank_row_wrapper {
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            .tanks_list_table {
                
                .input_field.is_checkbox {
                    display: block;
                    margin: 10px 2px;
                }
            }

            .buttons_wrapper {
                display: flex;
                flex-direction: row;   
                justify-content: space-evenly;     
                margin-top: -10px;    
            }
        }
    }

    h6 {
        margin-top: 20px;
        margin-bottom: 0px;
    }
}