@use "../../../styles/variables";
@use "../../../styles/mixins";


.cookie_settings {
  background-color: variables.$White;
  padding: 20px;

  h5 {
    margin-bottom: 0px;
  }

  .cookie_settings_content {
    margin-top: 15px;
  }

}

.cookie_wrapper {
  background-color: variables.$RedWine;
  padding: 20px;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 80%;
  margin-left: 320px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 10;

  .cookie_content {
    display: flex;
    flex-direction: row;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;

    p {
      color: variables.$White;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }
  }
}
