.tanks_title {
  margin-bottom: 32px !important;
}

.tanks_wrapper {
  flex-wrap: wrap;
  justify-content: space-between !important;
  margin-left: 0 !important;
  flex-direction: column !important;
  width: 100%;
  
  .tank_header {
    margin-bottom: 0;
  }

  .tank_name {
    width: 100%;
    text-align: center;

    .additional {
      &.smaller {
        font-size: 14px !important;
        font-weight: 400 !important;
      }
    }
  }

  .arrow {
    margin-top: 10px !important;

    div {
      transform: rotate(90deg);
    }

    .fake-line-wrapper {
      .fake-line:after {
        content:"";
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 50%;
        border-left: 2px solid black;
        transform: translate(-50%);
    }
      
      div {
          margin-bottom: -10px;
          width: 60%;
          height: 17px;
          position: relative;
          text-align: center
      }
    }
  }

  .tanks_recap_wrapper {
    justify-content: space-between !important;
  }
  
  .cta_wrapper {
      margin-top: -20px;
      margin-right: auto;
      width: 100%;

      .cta {
        width: 100%;
        justify-content: center;
        gap: 16px;
      }
  }
}