@use '../../../styles/variables';

.tanks_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    // gap: 16px;
    justify-content: center;
    // margin-left: 15px;
    margin-top: 16px;
    margin-bottom: 16px;

    &.no-center {
        justify-content: flex-start;
    }
    
    .batch_name {
            &.bold {
            font-weight: 500;
        }
    }

    .cta_wrapper {
        min-width: 200px;
    }

    // .arrow {
    //     margin-top: 40px;
    // }
    
    .tanks_recap {
        margin-left: auto;
        min-width: 200px;
        
        .tanks_recap_wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;
            flex-wrap: wrap;

            .tooltip_label {
                font-weight: 500;
                
                &.empty {
                    margin-top: 5px;
                }
            }
        }
    }
}

.tanks_title {
    margin-bottom: 5px;
    text-align: right;
}

.tanks_span {
    margin-left: 50px;
    font-weight: 400;
}
