@use '../../../styles/variables';

.table_base {
    &_header {
        padding: 16px 8px;
        &_column {
            font-weight: 600;
            display: flex;
            gap: 4px;
            justify-content: space-between;
            &_text {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        &_resizer {
            height: 100%;
            width: 3px;
            background: variables.$Black50;
            margin-right: 4px;
        }
    }
    &_body {
        &_row {
            padding: 16px 8px;

            &.analysis {
                padding: 2px 8px;
            }

            align-items: center;
            border-radius: variables.$ButtonRadius;
            position: relative;
            transition: all variables.$TransitionTime variables.$TransitionMode;
            &.clickable {
                cursor: pointer;
                &:hover {
                    background: variables.$GlassLightBlue50;
                }
            }
            &.highlight {
                background-color: variables.$RedWine5;
            }
            &:nth-child(even) {
                // background: variables.$White50;
            }
            &::after {
                position: absolute;
                bottom: 0;
                content: "";
                width: calc(100% - 12px);
                left: 50%;
                transform: translate(-50%);
                height: 1px;
                background: variables.$Black50;
            }
        }
        &_cell {
            // overflow: hidden;
            text-overflow: ellipsis;
            // white-space: nowrap;
            white-space: unset;
        }
    }

    .search-box{
        width: fit-content;
        height: fit-content;
        position: relative;
      }
      
      .input-search{
        height: 50px;
        width: 50px;
        border-style: none;
        padding: 10px;
        font-size: 18px;
        letter-spacing: 2px;
        outline: none;
        border-radius: 25px;
        transition: all .5s ease-in-out;
        background-color: variables.$RedWine;
        padding-right: 40px;
        color: variables.$Black;
      }
      .input-search::placeholder{
        color: variables.$RedWine;
        font-size: 15px;
        letter-spacing: 2px;
        font-weight: 300;
      }
      .btn-search{
        width: 50px;
        height: 50px;
        border-style: none;
        font-size: 20px;
        font-weight: bold;
        outline: none;
        cursor: pointer;
        border-radius: 50%;
        position: absolute;
        right: 0px;
        color: variables.$White;
        background-color: transparent;
        pointer-events: painted;  
      }
      .btn-search:focus ~ .input-search{
        width: 300px;
        border-radius: 0px;
        background-color: transparent;
        border-bottom:1px solid variables.$RedWine;
        transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
      }
      .input-search:focus{
        width: 300px;
        border-radius: 0px;
        background-color: transparent;
        border-bottom:1px solid variables.$RedWine;
        transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
      }      
}