@use '../../../styles/variables';

.secondary_bar {
    &_container {
        z-index: -1;
        left: 0;
        top: 0;
        margin-top: -16px;
        height: 32px;
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        /* Large or high resolution screen */
        @media all and (max-width: 1690px) {

        }

        /* Desktop or laptop */
        @media all and (min-width: 1280px) and (max-width: 1689px) {

        }

        // /* Tablet in horizontal view */
        // @media all and (min-width: 737px) and (max-width: 1279px) {
        // }

        // /* Tablet in vertical view */
        // @media all and (min-width: 481px) and (max-width: 736px) {
        // }

        /* Smartphone or small tablet */
        @media all and (max-width: 480px) {
            // margin-top: -40px;
        }
    }
    &_title {
        font-weight: 500;
        margin-top: 8px;
        display: flex;
        gap: 8px;

        /* Large or high resolution screen */
        @media all and (max-width: 1690px) {

        }

        /* Desktop or laptop */
        @media all and (min-width: 1280px) and (max-width: 1689px) {

        }

        // /* Tablet in horizontal view */
        // @media all and (min-width: 737px) and (max-width: 1279px) {
        // }

        // /* Tablet in vertical view */
        // @media all and (min-width: 481px) and (max-width: 736px) {
        // }

        /* Smartphone or small tablet */
        @media all and (max-width: 480px) {
            flex-wrap: wrap;
            gap: 4px;
        }

        & span {
            cursor: default;
            display: block;
            &::after {
                content: ">";
                display: inline-block; // Prevents inheritance of the underline style
                text-decoration: none !important; // Force removal of underline
                margin-left: 4px;
                cursor: default;
            }
            &:last-of-type {
                font-weight: 600;
                &::after {
                    content: "";
                }
            }

            &.secondary_bar_link {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
}